<template>
  <v-card>
      <v-data-table
        :headers="headers"
        :items="pacientes"
        :items-per-page="5"
        :search="search"        
        class="elevation-1"
      >
            <!-- name -->
            <template #[`item.tx_nombres`]="{item}">
              <div class="d-flex flex-column">
                <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.tx_nombres }}</span>
<!--                <small>{{ item.tx_nombres }}</small> -->
              </div>
            </template>          
            <template #[`item.tx_apellidos`]="{item}">
              <div class="d-flex flex-column">
                <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.tx_apellidos }}</span>
<!--                <small>{{ item.tx_nombres }}</small> -->
              </div>
            </template>          
          <!-- status -->
          <template #[`item.status`]="{item}">
            <v-chip
              small
              :color="statusColor[status[item.status]]"
              class="font-weight-medium"
            >
              {{ status[item.status] }}
            </v-chip>
          </template>

      </v-data-table>    
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import data from './datatable-data'
import { SET_ERROR } from '@/store/mutations.type'
import { MUESTRA_PACIENTE } from '@/store/actions.type'
export default {
  data () {
    return {
      pacientes: [],
      search: '',      
    }
  },
  setup() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Current: 'primary',
      Professional: 'success',
      Rejected: 'error',
      Resigned: 'warning',
      Applied: 'info',
      /* eslint-enable key-spacing */
    }

    return {
        headers: [
          /*{
            text: 'Nombres',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          */
          { text: 'Apellidos', value: 'tx_apellidos' },
          { text: 'Nombres', value: 'tx_nombres' },
          { text: 'Dirección', value: 'tx_direccion' },
          { 
            text: 'Teléfonos', 
            value: 'tx_telefono_celular' ,
            sortable: false,
          },
          { 
            text: 'Correo', 
            value: 'tx_correo', 
            sortable: false,

          },
        ],

      usreList: data,
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      statusColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
  methods: {
    searchPaciente(q) {
      this.$store.dispatch(MUESTRA_PACIENTE, {q: q}).then(response => {
          this.pacientes = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },  
  },
  mounted () {
    //this.searchPaciente({})
  }  
}
</script>
