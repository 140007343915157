<template>
  <v-col>
    <v-row  no-gutters>
      <v-col
        cols="12"
      >
        <dashboard-statistics-card></dashboard-statistics-card>
      </v-col>


      <v-col cols="12"  v-if="currentUser.username">
        <dashboard-datatable></dashboard-datatable>
      </v-col>
  </v-row>
</v-col>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {  mdiHelpCircleOutline } from '@mdi/js'

// demos
import DashboardStatisticsCard from './DashboardStatisticsCard.vue'
import DashboardDatatable from './DashboardDatatable.vue'
 import { useVuex } from '@vueblocks/vue-use-vuex'

export default {
  components: {
    DashboardStatisticsCard,
    DashboardDatatable,
  },
  setup() {

    const salesQueries = {
      statTitle: 'Sales Quries',
      icon: mdiHelpCircleOutline,
      color: 'warning',
      subtitle: 'Last week',
      statistics: '15',
      change: '-18%',
    };
    const { useGetters } = useVuex()    

    return {
      salesQueries,
      ...useGetters([
        'currentUser',
        // ...
      ]),         
    }
  },
}
</script>
